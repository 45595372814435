import Auth from '@aws-amplify/auth'
import { CognitoUserInterface } from '@aws-amplify/ui-components'
import Button from '@gameonsports/components/cjs/Button'
import Input from '@gameonsports/components/cjs/Input'
import Loader from '@gameonsports/components/cjs/Loader'
import Notification from '@gameonsports/components/cjs/Notification'
import PasswordInput from '@gameonsports/components/cjs/PasswordInput'
import PasswordValidator, {
  passwordValidationRule,
} from '@gameonsports/components/cjs/PasswordValidator'
import { Text } from '@gameonsports/components/cjs/TextV3'
import { Field, FieldProps, Formik } from 'formik-1.5.8'
import React, { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import styled from 'styled-components'
import * as Yup from 'yup'
import { useSupportedCountriesQuery } from '../../generated/graphql'
import { media } from '../../utils/styled-components-utils'
import BoxContainer from '../BoxContainer'
import MobileNumberInput, {
  mobileCountryCodeValidation,
  mobileNumberValidation,
} from '../MobileNumberInput'

const Container = styled.div`
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;

  ${media.tablet`
    padding: 4.5rem 2rem;
  `}
`

const StyledBox = styled(BoxContainer)`
  display: grid;
  justify-content: center;
  grid-template-columns: minmax(auto, 24rem);
  grid-gap: 1.5rem;
  max-width: 60rem;
  padding: 1rem;
  width: 100%;

  ${media.tablet`
    padding: 3rem 1rem;
    grid-gap: 2.5rem;
  `}
`

const Form = styled.form`
  display: grid;
  grid-gap: 3.5rem;
`

const FieldsContainer = styled.div`
  display: grid;
  grid-gap: 1.5rem;
`

const HeadingContainer = styled.div`
  display: grid;
  grid-gap: 1rem;
  text-align: center;

  h1 {
    margin: 0;
  }
`

const FormContainer = styled.div`
  display: grid;
  grid-gap: 2rem;
`

const StyledPasswordValidator = styled(PasswordValidator)`
  margin-top: 0.5rem;
`

export interface NewPasswordFormValues {
  password: string
  confirmPassword: string
  firstName: string
  lastName: string
  email: string
  mobileAlpha2Code: string
  mobileCountryCode: string
  mobileNumber: string
}

const NewPassword: React.FC<{
  user: CognitoUserInterface
  redirectPath: string
}> = ({ user, redirectPath }) => {
  const [hasError, setHasError] = useState(false)

  const { data, error, loading } = useSupportedCountriesQuery({
    onError: () => null,
  })

  if (loading) {
    return <Loader />
  }

  if (error || !data || !data.configuration) {
    return (
      <Notification variant="error">
        There was an error while trying to load the password reset
      </Notification>
    )
  }

  // @ts-ignore amplify types suck
  const { requiredAttributes } = user.challengeParam
  const { supportedCountries } = data.configuration

  const mobileNumberSupportedCountries = supportedCountries || []

  const validationSchema = Yup.object().shape({
    password: passwordValidationRule,
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password')], 'Passwords must match')
      .required('Confirm New Password is required'),
    ...(requiredAttributes.includes('given_name') && {
      firstName: Yup.string()
        .max(60, 'First Name must be 60 characters or less')
        .required('First Name is required'),
    }),
    ...(requiredAttributes.includes('family_name') && {
      lastName: Yup.string()
        .max(60, 'Last Name must be 60 characters or less')
        .required('Last Name is required'),
    }),
    ...(requiredAttributes.includes('email') && {
      email: Yup.string()
        .email('Email must be valid')
        .required('Email is required'),
    }),
    ...(requiredAttributes.includes('phone_number') && {
      mobileCountryCode: mobileCountryCodeValidation(
        mobileNumberSupportedCountries,
      ),
      mobileNumber: Yup.string().when(
        'mobileCountryCode',
        (mobileCountryCode: string, schema: Yup.StringSchema<string>) =>
          mobileNumberValidation({
            mobileCountryCode,
            supportedCountries: mobileNumberSupportedCountries,
            schema,
          }),
      ),
    }),
  })

  return (
    <Container>
      <Helmet title="Change password" />
      <StyledBox>
        <HeadingContainer>
          <Text size="32" weight="700" as="h1">
            Change password
          </Text>
        </HeadingContainer>
        <FormContainer>
          <Formik<NewPasswordFormValues>
            initialValues={{
              password: '',
              confirmPassword: '',
              firstName: '',
              lastName: '',
              email: '',
              mobileAlpha2Code: '',
              mobileCountryCode: '',
              mobileNumber: '',
            }}
            onSubmit={async values => {
              await Auth.completeNewPassword(
                user,
                values.confirmPassword,
                requiredAttributes.reduce(
                  (acc: { [x: string]: string }, curr: string) => ({
                    ...acc,
                    [curr]:
                      curr === 'given_name'
                        ? values.firstName
                        : curr === 'family_name'
                        ? values.lastName
                        : curr === 'phone_number'
                        ? `${values.mobileCountryCode}${values.mobileNumber
                            .replace(/ /g, '')
                            .substring(1)}`
                        : curr === 'email'
                        ? values.email
                        : undefined,
                  }),
                  {},
                ),
              )
                .then(() => {
                  // User is now logged in
                  window.location.assign(redirectPath)
                })
                .catch(() => {
                  setHasError(true)
                })
            }}
            validationSchema={validationSchema}
          >
            {formProps => (
              <Form method="post" noValidate onSubmit={formProps.handleSubmit}>
                <FieldsContainer>
                  {hasError && (
                    <Notification variant="error">
                      There are some issues with your new password. Please
                      correct the issues to reset your password.
                    </Notification>
                  )}

                  {requiredAttributes.includes('given_name') && (
                    <Field name="firstName">
                      {({ field }: FieldProps) => (
                        <Input
                          {...field}
                          id={field.name}
                          type="text"
                          placeholder="Please type"
                          label="First name"
                          data-testid="firstName-field"
                          error={formProps.errors.firstName}
                          touched={formProps.touched.firstName}
                          required
                        />
                      )}
                    </Field>
                  )}

                  {requiredAttributes.includes('family_name') && (
                    <Field name="lastName">
                      {({ field }: FieldProps) => (
                        <Input
                          {...field}
                          id={field.name}
                          type="text"
                          placeholder="Please type"
                          label="Last name"
                          data-testid="lastName-field"
                          error={formProps.errors.lastName}
                          touched={formProps.touched.lastName}
                          required
                        />
                      )}
                    </Field>
                  )}

                  {requiredAttributes.includes('email') && (
                    <Field name="email">
                      {({ field }: FieldProps) => (
                        <Input
                          {...field}
                          id={field.name}
                          type="email"
                          touched={formProps.touched.email}
                          placeholder="Please enter"
                          label="Email"
                          error={formProps.errors.email}
                          data-testid="email-field"
                          required
                        />
                      )}
                    </Field>
                  )}

                  {requiredAttributes.includes('phone_number') && (
                    <MobileNumberInput
                      {...formProps}
                      supportedCountries={mobileNumberSupportedCountries}
                    />
                  )}

                  <div>
                    <Field name="password">
                      {({ field }: FieldProps) => (
                        <PasswordInput
                          {...field}
                          id={field.name}
                          placeholder="Please type"
                          label="New Password"
                          data-testid="password-field"
                          error={formProps.errors.password}
                          touched={formProps.touched.password}
                          hideError
                          required
                        />
                      )}
                    </Field>
                    {formProps.values.password && (
                      <StyledPasswordValidator
                        value={formProps.values.password}
                      />
                    )}
                  </div>

                  <Field name="confirmPassword">
                    {({ field }: FieldProps) => (
                      <PasswordInput
                        {...field}
                        id={field.name}
                        placeholder="Please type"
                        label="Confirm New Password"
                        data-testid="confirm-password-field"
                        error={formProps.errors.confirmPassword}
                        touched={formProps.touched.confirmPassword}
                        required
                      />
                    )}
                  </Field>
                </FieldsContainer>

                <Button
                  variant="primary"
                  type="submit"
                  disabled={!formProps.isValid}
                  data-testid="submit-new-password"
                >
                  Change password
                </Button>
              </Form>
            )}
          </Formik>
        </FormContainer>
      </StyledBox>
    </Container>
  )
}

export default NewPassword
