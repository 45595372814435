import { gql, useQuery } from '@apollo/client'
import Auth from '@aws-amplify/auth'
import { CognitoUserInterface } from '@aws-amplify/ui-components'
import useSignIn, {
  ErrorCode,
} from '@gameonsports/aws-amplify-hooks/cjs/useSignIn'
import { useToggle } from '@gameonsports/components/cjs/_hooks/useToggle'
import { media } from '@gameonsports/components/cjs/_utils/styled-components-utils'
import Button from '@gameonsports/components/cjs/Button'
import Icon from '@gameonsports/components/cjs/Icon'
import Input from '@gameonsports/components/cjs/Input'
import Loader from '@gameonsports/components/cjs/Loader'
import Modal from '@gameonsports/components/cjs/Modal'
import Notification from '@gameonsports/components/cjs/Notification'
import PasswordInput from '@gameonsports/components/cjs/PasswordInput'
import { Text } from '@gameonsports/components/cjs/TextV3'
import {
  Link,
  RouteComponentProps,
  WindowLocation,
  navigate,
} from '@reach/router'
import { Field, FieldProps, Formik } from 'formik-1.5.8'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import styled from 'styled-components'
import * as Yup from 'yup'
import AuthenticationBoundary from '../../components/AuthenticationBoundary'
import BoxContainer from '../../components/BoxContainer'
import NewPassword from '../../components/NewPassword'
import SectionContainer from '../../components/SectionContainer'
import { EXTERNAL_LOGIN_PROVIDER_CALLBACK } from '../../constants/registrationPaths'
import { LogLevel, pushLog } from '../../utils/faro'
import MfaChallenge from '../MfaChallenge/MfaChallenge'
import { AFLiDLoginButton } from '../RegistrationExternalID/components/AflID/AflIDLoginButton'
import { useRedirectPath } from './hooks/useRedirectPath'

export const REGISTRATION_TYPE = gql`
  query registrationType($code: String!) {
    registrationSettings(code: $code) {
      ... on DiscoverRegistration {
        id
        type
      }
      ... on DiscoverSocialTeamRegistration {
        id
      }
    }
  }
`

const OuterContainer = styled.div`
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;

  ${media.tablet`
    padding: 4.5rem 2rem;
  `}
`

const StyledBox = styled(BoxContainer)`
  max-width: 60rem;
  width: 100%;
`

const Form = styled.form`
  display: flex;
  flex-direction: column;

  & > * + * {
    margin-top: 3.5rem;
  }
`

const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;

  & > * + * {
    margin-top: 1.5rem;
  }
`

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  & > * + * {
    margin-top: 2rem;
  }
`

const HelpLink = styled(Text)<React.AnchorHTMLAttributes<{}>>`
  text-decoration: none;
  justify-self: center;
  align-self: center;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
`

const Container = styled.div`
  /* Flex for IE 11 */
  display: flex;
  display: grid;

  /* stylelint-disable-next-line */

  & > * {
    flex: 1;
  }

  ${media.tablet`
    grid-template-columns: repeat(2, 1fr);
  `}
`

const LoginColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 2rem;
  border-bottom: 1px solid ${props => props.theme.bodyBackground};

  & > * + * {
    margin: 2rem 0 0;
  }

  h1 {
    margin: 0;
    display: inline-flex;
    align-items: center;
    color: ${props => props.theme.blackberry400};
  }

  .logo {
    width: 4.25rem;

    /* stylelint-disable-next-line declaration-property-unit-allowed-list */
    margin: 1px 0.25rem 0;
  }

  ${media.tablet`
    padding-right: 3rem;
    border-bottom: none;
    border-right: 1px solid ${props => props.theme.bodyBackground};
  `};
`

const RegisterColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-items: center;
  align-items: center;
  padding: 2rem 1rem 0;
  text-align: center;

  & > * + * {
    margin-top: 1.5rem;
  }

  ${media.tablet`
    padding: 3rem;
  `}
`

const LoginButton = styled(Button)`
  justify-self: center;
  align-self: center;
`

const ErrorContainer = styled.div`
  display: grid;
  grid-gap: 1.5rem;
  text-align: center;
  padding: 5rem 3.75rem;
`

const StyledModal = styled(Modal)`
  max-width: 30rem;
  padding: 3rem;
  text-align: center;

  > * {
    margin-left: auto;
    margin-right: auto;
  }
`

const getErrorMessage = (errorCode: ErrorCode) => {
  if (
    errorCode === ErrorCode.UserNotFoundException ||
    errorCode === ErrorCode.NotAuthorizedException
  ) {
    return 'Incorrect email address and/or password'
  }

  if (errorCode === ErrorCode.UserNotConfirmedException) {
    return (
      <span data-testid="user-not-confirmed-error">
        Please verify your email address to login. Visit{' '}
        <a
          href="https://support.playhq.com/hc/en-au/articles/900003258286-Verifying-your-PlayHQ-account"
          target="_blank"
          rel="noopener noreferrer"
        >
          support
        </a>{' '}
        for assistance.
      </span>
    )
  }

  return 'Something went wrong. Please try again later.'
}

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Email must be valid')
    .required('Email is required'),
  password: Yup.string().required('Password is required'),
})

interface LoginFormValues {
  email: string
  password: string
}

const Login: React.FC<
  RouteComponentProps<{ registrationCode?: string; tenant: string }> & {
    location?: WindowLocation<{
      notification: string
      errorNotification: string
    }>
  }
> = ({ registrationCode, location, tenant }) => {
  const [regCode] = String(registrationCode).split('-')
  const [isNewPasswordRequired, setNewPasswordRequired] = useState(false)
  const [isSignupModalOpen, toggleSignupModal] = useToggle()
  const [mfaChallengeType, setMfaChallengeType] = useState<
    'SOFTWARE_TOKEN_MFA' | 'SMS_MFA' | undefined
  >(undefined)
  const [loginDetails, setLoginDetails] = useState<
    { email: string; password: string } | undefined
  >(undefined)

  const { isQueryParamAPath, getPathFromQueryParam } = useRedirectPath()

  // The shop checkout URL to redirect to after login
  const signupUrl = `/signup${
    isQueryParamAPath('redirectPath')
      ? `?redirectPath=${encodeURIComponent(
          String(getPathFromQueryParam('redirectPath')),
        )}`
      : ''
  }`

  const [signIn, result] = useSignIn()
  const {
    data: registrationData,
    error: registrationError,
    loading: registrationLoading,
  } = useQuery(REGISTRATION_TYPE, {
    variables: {
      code: regCode,
    },
    skip: !registrationCode,
    onError: () => null,
  })

  const user = result.user as unknown as CognitoUserInterface
  const loading = result.loading
  const error = result.error
  const signedIn = result.signedIn

  useEffect(() => {
    if (user && 'challengeName' in user) {
      // @ts-ignore
      const challengeName = user.challengeName
      switch (challengeName) {
        case 'NEW_PASSWORD_REQUIRED':
          setNewPasswordRequired(true)
          break
        case 'SOFTWARE_TOKEN_MFA':
        case 'SMS_MFA':
          setMfaChallengeType(challengeName)
          break
        default:
          pushLog(['UNEXPECTED_CHALLENGE'], {
            level: LogLevel.ERROR,
            context: {
              description: challengeName,
            },
          })
      }
    }
  }, [user])

  if (registrationLoading) {
    return <Loader />
  }

  const redirectPath =
    getPathFromQueryParam('redirectPath') ||
    (registrationData && registrationData.registrationSettings
      ? 'type' in registrationData.registrationSettings
        ? `/${tenant}/register/${registrationCode}/participant/1`
        : `/${tenant}/register/${registrationCode}/team/1`
      : '/')

  if (
    registrationCode &&
    (registrationError ||
      !registrationData ||
      !registrationData.registrationSettings ||
      !redirectPath)
  ) {
    return (
      <ErrorContainer>
        <Text weight="700" size="22">
          Something went wrong.
        </Text>
        <Text>
          There was a problem getting the details for you to log in. Please try
          again later.
        </Text>
      </ErrorContainer>
    )
  }

  if (signedIn && !loading) {
    window.location.assign(redirectPath)
  }

  if (isNewPasswordRequired && user) {
    return <NewPassword user={user} redirectPath={redirectPath} />
  }

  if (!!mfaChallengeType && user && loginDetails) {
    return (
      <MfaChallenge
        user={user}
        loginDetails={loginDetails}
        redirectPath={redirectPath}
        confirmSignIn={Auth.confirmSignIn.bind(Auth)}
        mfaType={mfaChallengeType}
      />
    )
  }

  const Wrapper: React.FC = ({ children }) =>
    registrationCode ? (
      <>{children}</>
    ) : (
      <OuterContainer>
        <StyledBox>{children}</StyledBox>
      </OuterContainer>
    )

  return (
    <SectionContainer noYPadding>
      <Helmet title="Log In" />
      <AuthenticationBoundary
        onAuthenticated={() => {
          const storedRedirectPath = localStorage.getItem(
            EXTERNAL_LOGIN_PROVIDER_CALLBACK,
          )

          if (storedRedirectPath && redirectPath === '/') {
            navigate(storedRedirectPath)
          } else {
            navigate(redirectPath)
          }
        }}
      >
        <Formik<LoginFormValues>
          initialValues={{
            email: '',
            password: '',
          }}
          onSubmit={values => {
            if (loading) {
              return
            }

            setLoginDetails({
              email: values.email.toLowerCase(),
              password: values.password,
            })
            return signIn(values.email.toLowerCase(), values.password)
          }}
          validationSchema={validationSchema}
        >
          {({ handleSubmit, errors, touched }) => (
            <Wrapper>
              <Container>
                <LoginColumn>
                  <Text size="18" weight="700" as="h1">
                    Please log in with your PlayHQ account
                  </Text>
                  <FormContainer>
                    <Form method="post" noValidate onSubmit={handleSubmit}>
                      <FieldsContainer>
                        {location?.state?.notification && (
                          <Notification>
                            {location.state.notification}
                          </Notification>
                        )}
                        {location?.state?.errorNotification && (
                          <Notification variant="error">
                            {location.state.errorNotification}
                          </Notification>
                        )}

                        {error && (
                          <Notification variant="error">
                            {getErrorMessage(error.code)}
                          </Notification>
                        )}

                        <Field name="email">
                          {({ field }: FieldProps) => (
                            <Input
                              {...field}
                              id={field.name}
                              type="text"
                              placeholder="Please type"
                              label="Email"
                              data-testid="email-field"
                              error={errors.email}
                              touched={touched.email}
                              required
                            />
                          )}
                        </Field>
                        <Field name="password">
                          {({ field }: FieldProps) => (
                            <PasswordInput
                              {...field}
                              id={field.name}
                              placeholder="Please type"
                              label="Password"
                              data-testid="password-field"
                              error={errors.password}
                              touched={touched.password}
                              required
                            />
                          )}
                        </Field>
                      </FieldsContainer>

                      <LoginButton
                        variant="primary"
                        type="submit"
                        size="wide"
                        loading={loading}
                        data-testid="submit-login"
                      >
                        Log in
                      </LoginButton>
                    </Form>

                    <AFLiDLoginButton redirect={redirectPath} />

                    <HelpLink
                      as="a"
                      href={`/forgot-password${
                        registrationCode && tenant
                          ? `?registrationCode=${registrationCode}&tenant=${tenant}`
                          : ''
                      }`}
                      target={registrationCode ? '_blank' : undefined}
                      color="darkGrey400"
                      data-testid="forgot-password-link"
                    >
                      Forgot password?
                    </HelpLink>
                  </FormContainer>
                </LoginColumn>
                <RegisterColumn>
                  <Text size="18" weight="700">
                    I don't have an account yet
                  </Text>
                  <Button
                    {...(registrationCode && tenant
                      ? {
                          as: 'button',
                          onClick: () => toggleSignupModal(),
                        }
                      : {
                          as: Link,
                          to: signupUrl,
                        })}
                    size="wide"
                    disabled={loading}
                    data-testid="create-account-link"
                    variant="primary"
                  >
                    Create an account
                  </Button>
                </RegisterColumn>
              </Container>
            </Wrapper>
          )}
        </Formik>
        {isSignupModalOpen && (
          <StyledModal onClickOutside={toggleSignupModal}>
            <Icon name="info" size="24" color="blackberry400" />
            <Text as="h2" size="20" color="blackberry400" weight="700">
              Please create a PlayHQ account with your details, even if
              registering a child
            </Text>
            <Text as="p" color="darkGrey400">
              You can then register family members or dependants under this
              account.
            </Text>
            <Button
              as={Link}
              to={`/signup${
                registrationCode && tenant
                  ? `?registrationCode=${registrationCode}&tenant=${tenant}`
                  : ''
              }`}
              variant="primary"
            >
              Continue
            </Button>
          </StyledModal>
        )}
      </AuthenticationBoundary>
    </SectionContainer>
  )
}

export default Login
